<template>
  <div class="Pcabout">
    <div class="Pcabout1">
      <img src="@/assets/pcImg/home/home1.png" style="width: 100%">
    </div>
    <div class="Pcabout2">
      <img src="@/assets/pcImg/home/home2_1.png"
           style=" width: 153px;height: 102px;position: absolute;bottom: 0;left: 0">
      <img src="@/assets/pcImg/home/home2_2.png" style=" width: 143px;height: 254px;position: absolute;top: 0;right: 0">
      <div class="Pcabout2_1">
        <div style="font-size: 40px;font-weight: bold;">俏皮童话绘本成长中心</div>
        <div class="Pcabout2_11">
          <div style="width: 256px;height: 168px; margin-top: 30px;" v-for="(item,index) in chengjiuData" :key="item.newsId+index" class="Pcabout2_12H">
            <div class="Pcabout2_12">
              <div>
                {{item.newsTitle}}
              </div>
              <div>
                {{item.newsSubtitle}}
              </div>
            </div>
            <img :src="item.newsImage" style="width: 100%;height: 100%">
          </div>
        </div>
      </div>
    </div>
    <div class="Pcabout3">
      <img src="@/assets/pcImg/home/home3.png" style="width: 100%">
    </div>
    <div class="Pcabout4">
      <div style="font-size: 40px;font-weight: bold;width: 100%;text-align: left">品牌实力</div>
      <div style="width: 142px;height: 129px;position: absolute;right: 0;top: 0">
        <img src="@/assets/pcImg/about1.png" style="width: 100%">
      </div>
      <div class="Pcabout4_1">
        <div  class="Pcabout4_1Box" v-for="(item,index) in shiliData" :key="item.newsId+index">
          {{item.newsTitle}}
        </div>
      </div>
    </div>
    <div class="Pchome7">
      <div class="Pchome7scroll-content" :style="contentStyle()">
        <div class="Pchome7content1 Pchome7content">
          <div style="width: 168px;height: 64px;margin-left: 40px;margin-top: 20px" v-for="item in 7">
            <img :src="require('../../../assets/pcImg/home/ppf/ppf'+item+'.png')" style="width: 100%">
          </div>
        </div>
        <div class="Pchome7content2 Pchome7content">
          <div style="width: 168px;height: 64px;margin-left: 40px;margin-top: 20px" v-for="item in 8">
            <img :src="require('../../../assets/pcImg/home/ppf/ppfa'+item+'.png')" style="width: 100%">
          </div>
        </div>
        <div class="Pchome7content3 Pchome7content">
          <div style="width: 168px;height: 64px;margin-left: 40px;margin-top: 20px" v-for="item in 9">
            <img :src="require('../../../assets/pcImg/home/ppf/ppfb'+item+'.png')" style="width: 100%">
          </div>
        </div>
      </div>
      <div class="Pchome7Back">
        <div style="font-size: 48px;font-weight: bold">合作媒体支持</div>
        <div style="font-size: 32px;font-weight: bold">MEDIA  SUPPORT</div>
      </div>
    </div>
  </div>
</template>
<script >

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api/index'

export default {
  name: 'mobilePage',
  data () {
    return {
      chengjiuData:[],
      shiliData:[],
      ppslData:[
        {
          name:"xxxxxxxxxxxxxxxxxxxxxxxxx"
        },  {
          name:"教育意义在于xxxxx"
        },  {
          name:"xxxxxxxxxxxxxxxx"
        },  {
          name:"xxxxxxxxxxxxxxxxxxxxxxxxx"
        },  {
          name:"xxxxxxxxxxxxxxxxxxxx"
        },  {
          name:"xxxxxxxxxxxxxxxxxxxxxxxxx"
        },  {
          name:"xxxxxxxxxxxxxxxxxxxxx"
        },  {
          name:"xxxxxxxxxxxxxxxxx"
        },  {
          name:"xxxxxxx"
        },  {
          name:"xxxxxxxxxxxxxxxxxxxxxxxxx"
        },  {
          name:"xxxxxxxxxxxxxxxxxxx"
        },  {
          name:"xxxxxxxxxxxxxxxxxx"
        },  {
          name:"xxxxxxxxxxxxxxxxxxxxxxxxx"
        },  {
          name:"xxxxxxxxxxxxxx"
        },  {
          name:"xxxxxxxxxxxxxxxxxxx"
        },
      ],
      position: 0,
    }
  },
  // 注册组件
  components: {
  },
  mounted() {
    this.startScroll();
  },
  methods: {
    getChengjiu(){
      let parmes={
        size: 999,
        current: 1,
        newsType: 4
      }
      newsByPage(parmes).then(res => {
        console.log(res.data.data.records)
        this.chengjiuData = res.data.data.records
      })
       let parmes2={
        size: 999,
        current: 1,
        newsType: 6
      }
      newsByPage(parmes2).then(res => {
        this.shiliData = res.data.data.records
      })

    },
    contentStyle() {
      return {
        transform: `translateX(${this.position}px)`,
        transition: 'transform 0.5s ease-in-out',
      };
    },
    startScroll() {
      const contentWidth = 1400; // 单个内容的宽度
      const containerWidth = 1200; // 容器宽度
      const resetPosition = containerWidth;

      setInterval(() => {
        this.position -= 1;

        // 当滚动内容即将离开视图时，重置位置
        if (-this.position >= contentWidth) {
          setTimeout(() => {
            this.position = resetPosition;
          }, 500); // 这个延迟应该与CSS过渡时间相匹配
        }
      }, 16); // 16毫秒大约是60FPS的帧率
    },
  },
  created () {
    this.getChengjiu()
  }
}
</script>
<style>
.Pchome7content1 {
  display: flex;
  justify-content: end;
}

.Pchome7content2 {
  display: flex;
  justify-content: center;
}

.Pchome7content3 {
  display: flex;
  justify-content: start;
}

.Pchome7content {
  width: 1900px;
  line-height: 2;
}
.Pchome7Back{
  text-align: left;
  padding: 50px 50px 10px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient( 147deg, #EBFFAA 0%, rgba(226,254,143,0.8) 44%, rgba(209,250,90,0) 100%);
  border-radius: 30px;
}
.Pchome7 {
  width: 1200px;
  height: 324px;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 100px;
  position: relative;
  overflow: hidden;
  padding-top: 40px;
  box-sizing: border-box;
}
.Pcabout4_1Box{
  margin-top: 40px;
  margin-left: 20px;
  background: #E8F5FF;
  border-radius: 20px;
  padding: 10px;
}
.Pcabout4_1{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 40px;
}
.Pcabout4{
  width: 1200px;
  margin: auto;
  position: relative;
  margin-top: 80px;
}
.Pcabout3 {
  width: 100%;
  margin-top: 80px;
}

.Pcabout2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1325px;
  min-height: 800px;
  margin: auto;
  margin-top: 80px;
}

.Pcabout2_12H {
  cursor: pointer;
}

.Pcabout2_12H:hover .Pcabout2_12 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.Pcabout2_12 {
  display: none;
  overflow: auto;
  padding: 20px;
  //display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffc7;
  position: absolute;
  width: 256px;
  height: 168px;
  border-radius: 20px;
  font-weight: bold;
  box-sizing: border-box;
}

.Pcabout2_11 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.Pcabout2_1 {
  width: 1200px;
  min-height: 720px;
  background: #E8F5FF;
  border-radius: 20px;
  padding: 50px 30px 75px;
  box-sizing: border-box;
}

.Pcabout {
  padding-top: 40px;
  padding-bottom: 40px;
}

.Pcabout1 {
  width: 1200px;
  margin: auto;
}
</style>
