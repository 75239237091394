<template>
  <div class="seeApp">
    <div class="seeApp1">
      <img src="@/assets/pcImg/logo.png" style="width: 120px;height: 36px;margin: auto">
    </div>
    <div class="seeApp2">
      <div class="seeApp2_1">
        <img src="../../../assets/qrcode/xcx.png" style="width: 100%;height: 100%">
      </div>
    </div>
    <div style="font-size: 12px;margin: 20px auto;width: 90%;text-align: left">
      <div style="line-height: 2">
        俏皮童话书籍借阅系统，是俏皮童话专为绘本馆研发设计的一站式借阅平台。系统不仅让家长/读者能够轻松快捷地检索心仪绘本、完成图书借还流程，
        更助力馆长高效管理借阅会员信息，显著提升绘本馆的借阅效率与服务质量。
        俏皮童话书籍借阅系统，如同一座温馨而智慧的桥梁，让每一次借阅都是愉快的体验，让我们共同营造一个充满乐趣与想象的绘本世界。
        系统分为总部平台端、分馆门店端、会员用户端，实现了从后端管理到前端服务的全面覆盖，为绘本馆提供了强大的运营支持。
      </div>
<!--      <div style="font-weight: bold">尊敬的客户:</div>-->
<!--      <br/>-->
<!--      <div>-->
<!--        感谢您对我们公司的俏皮借阅应用程序的关注和支持。我们很高兴地告诉您，我们的开发团队正在努力工作，以确保这款应用程序能够为您和您的孩子带来最佳的使用体验。-->
<!--        <br/>-->
<!--        目前，我们的应用程序仍在开发阶段，尚未完全准备好发布。我们深知您对这款应用程序的期待，因此我们在此向您保证，我们将全力以赴，确保这款应用程序能够尽快与您见面。-->
<!--        <br/>-->
<!--        在未来的日子里，我们将继续努力，不断完善和优化这款应用程序的功能和用户体验。我们相信，这款绘本类应用程序将成为您和您的孩子阅读和学习的好伙伴。-->
<!--        <br/>-->
<!--        在此期间，我们恳请您保持耐心，并继续关注我们的进展。我们将在第一时间通知您关于这款应用程序的最新动态和发布信息。-->
<!--        <br/>-->
<!--        再次感谢您对我们公司的支持和信任。我们期待着与您共同见证这款绘本类应用程序的诞生，并为您的孩子带来无尽的欢乐和启发。-->
<!--      </div>-->
<!--      <div style="text-align: right">-->
<!--        <br/>-->
<!--        敬请期待！-->
<!--        <br/>-->
<!--        <br/>-->
<!--        【玖恒文化传播(广州)有限公司】-->
<!--      </div>-->
    </div>
  </div>
</template>
<script >

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api/index'

export default {
  name: 'mobilePage',
  data () {
    return {

    }
  },
  // 注册组件
  components: {
  },
  mounted() {

  },
  methods: {

  },
  created () {
  }
}
</script>
<style>
.seeApp1{
  width: 100%;
}
.seeApp2{
  width: 300px;
  height: 300px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient( 147deg, #EBFFAA 0%, rgba(226,254,143,0.8) 44%, rgba(209,250,90,0) 100%);
  border-radius: 30px;
}
.seeApp2_1{
  width: 250px;
  height: 250px;
  border-radius: 30px;
  background: #ffffff;
  //filter: blur(5px);
}
  .seeApp{
    padding-top: 20px;
    background: #E8F5FF;
    min-height: calc(100vh - 60px);
  }
</style>
