<template>
  <div class="mobilePage">
    <div class="mobilePage1">
      <div class="mobilePage1_1">
        <img src="@/assets/pcImg/logo.png" style="width: 120px;height: 36px;">
      </div>
      <div class="mobilePage1_1" @click="openMenus" :style="{transform: menusShow?'rotate(90deg)':''}">
        <img src="@/assets/phoneImg/menus2.png" style="width: 20px;height: 20px;">
      </div>
    </div>
    <div class="mobilePageMenus" v-if="menusShow" :style="{'top':menusIndex==5?'60px':''}">
      <ul>
        <li :style="{'list-style-type':menusIndex==0?'disclosure-closed':'none'}" @click="changeMenus(0)">关于俏皮童话</li>
        <li :style="{'list-style-type':menusIndex==1?'disclosure-closed':'none'}" @click="changeMenus(1)">绘本成长馆</li>
        <li :style="{'list-style-type':menusIndex==2?'disclosure-closed':'none'}" @click="changeMenus(2)">1+N 特色项目</li>
        <li :style="{'list-style-type':menusIndex==3?'disclosure-closed':'none'}" @click="changeMenus(3)">俏皮动态</li>
        <li :style="{'list-style-type':menusIndex==4?'disclosure-closed':'none'}" @click="changeMenus(4)">品牌周边</li>
        <li :style="{'list-style-type':menusIndex==5?'disclosure-closed':'none'}" @click="changeMenus(5)">俏皮借阅</li>
      </ul>
    </div>
    <div class="mobilePage2" v-if="menusIndex<5" :style="{'margin-top':menusIndex<5?'60px':''}">
      <img :src="require('@/assets/phoneImg/banner'+menusIndex+'.png')" style="width: 100%;">
    </div>

    <div class="conterBox1">
      <router-view ref="allchildHome" @changeMenus="changeMenus" :style="{'margin-top':menusIndex==5?'60px':''}"/>
    </div>
    <div class="mobilePage3" v-if="menusIndex<5">
        <div>
          COPYRIGHT ©2024 俏皮童话
        </div>
        <div>www.qiaopitonghua.com website.qipitonghua.com</div>
        <div>电话：400-110-6121/ 13189054761</div>
        <a href="https://beian.miit.gov.cn/"  style="color:black; text-decoration: none;">粤ICP备2023100241号-2</a>
        <div>联系地址：广东省广州市天河区金硕一路11号 汉银广场16层</div>
    </div>
  </div>
</template>
<script >

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api/index'

export default {
  name: 'mobilePage',
  data () {
    return {
        menusIndex:0,
        menusShow:false
    }
  },
  // 注册组件
  components: {
  },
  mounted() {

  },
  methods: {
    openMenus(){
      this.menusShow = !this.menusShow
    },
    changeMenus(index){
      this.menusIndex = index
      if(this.$route.path!==["/phoneHome","/phoneStoreData","/phoneAllProject","/phoneDynamicState","/phoneRimData","/phoneSeeApp"][parseInt(this.menusIndex)]){
        this.$router.push({ path: ["/phoneHome","/phoneStoreData","/phoneAllProject","/phoneDynamicState","/phoneRimData","/phoneSeeApp"][parseInt(this.menusIndex)] })
      }
      window.sessionStorage.setItem('key',this.menusIndex);
      this.menusShow = false
      this.$forceUpdate()
    }
  },
  created () {
    if(window.sessionStorage.getItem('key')){
      this.menusShow = false
      this.menusIndex = window.sessionStorage.getItem('key')
      if(this.$route.path!==["/phoneHome","/phoneStoreData","/phoneAllProject","/phoneDynamicState","/phoneRimData","/phoneSeeApp"][parseInt(this.menusIndex)]){
        this.$router.push({ path: ["/phoneHome","/phoneStoreData","/phoneAllProject","/phoneDynamicState","/phoneRimData","/phoneSeeApp"][parseInt(this.menusIndex)] })
      }
    }else {
      this.activeIndex =0
    }
  }
}
</script>
<style>
.mobilePage3{
  font-size: 12px;
  line-height: 2;
  padding: 50px;
  box-sizing: border-box;
}
.conterBox1{
  width: 100%;
  min-height: 600px;
}
.mobilePageMenus{
  z-index: 99;
  font-size: 16px;
  position: fixed;
  background: rgb(255 255 255 / 80%);
  width: 100%;
  height: 300px;
  text-align: left;
  box-sizing: border-box;
  line-height: 2.5;
}
  .mobilePage{
    width: 100vw;
  }
  .mobilePage2{
    width: 100%;
  }
  .mobilePage1{
    width: 100%;
    z-index: 9;
    height: 60px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px 10px 40px;
    position: fixed;
    top: 0;
    background: #FFFFFF;
  }
</style>
