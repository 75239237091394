import Vue from 'vue'
import VueRouter from 'vue-router'
/*PC端内容**/
import pcPage from '@/views/pcPage/index.vue'
import home from '@/views/pcPage/home/index.vue'
import about from '@/views/pcPage/about/index.vue'
import allproject from '@/views/pcPage/allproject/index.vue'
import seeApp from '@/views/pcPage/seeApp/index.vue'
import rimData from '@/views/pcPage/rimData/index.vue'
import dynamicState from '@/views/pcPage/dynamicState/index.vue'
import storeData from '@/views/pcPage/storeData/index.vue'


import phonePage from '@/views/phonePage/index.vue'
import phoneHome from '@/views/phonePage/home/index.vue'
import phoneAbout from '@/views/phonePage/about/index.vue'
import phoneAllProject from '@/views/phonePage/allproject/index.vue'
import phoneSeeApp from '@/views/phonePage/seeApp/index.vue'
import phoneRimData from '@/views/phonePage/rimData/index.vue'
import phoneDynamicState from '@/views/phonePage/dynamicState/index.vue'
import phoneStoreData from '@/views/phonePage/storeData/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'pcPage',
    component: pcPage,
    redirect: '/home',
    children: [ // 嵌套子路由
      {
        name: '首页',
        path: '/home',
        component: home
      },
      {
        name: '关于我们',
        path: '/about',
        component: about
      },
      {
        path: '/storeData',
        name: '俏皮成长馆',
        component: storeData
      },
      {
        path: '/allproject',
        name: '1+N特色项目',
        component: allproject
      },
      {
        path: '/dynamicState',
        name: '俏皮动态',
        component: dynamicState
      },
      {
        path: '/rimData',
        name: '品牌及周边',
        component: rimData
      },
      {
        path: '/seeApp',
        name: '俏皮借阅',
        component: seeApp
      }
    ]
  },{
    path: '/mobileHome',
    name: 'mobileHome',
    component: phonePage,
    redirect: '/phoneHome',
    children: [ // 嵌套子路由
      {
        name: '移动首页',
        path: '/phoneHome',
        component: phoneHome
      },
      {
        name: '关于 我们',
        path: '/phoneAbout',
        component: phoneAbout
      },
      {
        path: '/phoneStoreData',
        name: '俏皮 成长馆',
        component: phoneStoreData
      },
      {
        path: '/phoneAllProject',
        name: '1+N 特色项目',
        component: phoneAllProject
      },
      {
        path: '/phoneDynamicState',
        name: '俏皮 动态',
        component: phoneDynamicState
      },
      {
        path: '/phoneRimData',
        name: '品牌 及 周边',
        component: phoneRimData
      },
      {
        path: '/phoneSeeApp',
        name: '俏皮 借阅',
        component: phoneSeeApp
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
