<template>
  <div class="pcStoreData">
    <div style="width: 100%">
      <img src="@/assets/pcImg/storeData/storeData1.png" style="width: 99px;">
    </div>
    <div style="width: 100%;text-align: center;margin-top: 20px;font-size: 40px;font-weight: bold">
      俏皮品牌馆
    </div>
    <div class="pcStoreData1">
      <div style="width: 282px;">
        <img src="@/assets/pcImg/storeData/storeDataBox1.png" style="width: 282px;height: 171px">
        <img src="@/assets/pcImg/storeData/storeDataBox2.png" style="width: 282px;height: 171px;margin-top: 20px">
      </div>
       <div style="width: 282px;">
        <img src="@/assets/pcImg/storeData/storeDataBox3.png" style="width: 282px;height: 171px">
        <img src="@/assets/pcImg/storeData/storeDataBox4.png" style="width: 282px;height: 171px;margin-top: 20px">
      </div>
      <div style="width: 588px;">
        <img src="@/assets/pcImg/storeData/storeDataBox5.png" style="width: 588px;height: 366px">
      </div>
    </div>
    <div class="pcStoreData2">
      <img src="@/assets/pcImg/storeData/lxwm.png" style="width: 100%"/>
    </div>
    <div class="pcStoreData1">
      <div style="width: 282px;margin-top: 20px;" v-for="(item,index) in storesByListAllData" :key="item.storesName+index">
        <el-image
            style="width: 282px;height: 171px;border-radius: 20px"
            :src="item.storesImage"
            :preview-src-list="[item.storesImage]">
        </el-image>
        <div>{{item.storesName}}</div>
      </div>
    </div>
  </div>
</template>
<script >

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api/index'

export default {
  name: 'mobilePage',
  data () {
    return {
      // 获取店面地址
      storesByListAllData:[]
    }
  },
  // 注册组件
  components: {
  },
  mounted() {

  },
  methods: {
    getstoresByList(){
      storesByList().then(res => {
        this.storesByListAllData = res.data.data
      })
    },
  },
  created () {
    this.getstoresByList()
  }
}
</script>
<style>
.pcStoreData{
  padding-top: 40px;
  padding-bottom: 40px;
}
.pcStoreData2{
  width: 1200px;
  margin: auto;
  margin-top: 40px;
}
.pcStoreData1{
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;

}
</style>
