<template>
  <div class="pcPage">
    <div class="titleMenu">
      <el-row type="flex" class="row-bg" justify="center">
        <el-col :span="4">
          <div class="titleMenuBox">
            <img src="@/assets/pcImg/logo.png" style="width: 200px">
          </div>
        </el-col>
        <el-col :span="14">
          <div class="titleMenuBox">
            <div>
              <el-menu :default-active="activeIndex" text-color="#000000" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                <el-menu-item index="1">首页</el-menu-item>
                <el-submenu index="2">
                  <template slot="title">关于俏皮童话</template>
                  <el-menu-item index="2">俏皮童话简介</el-menu-item>
                  <el-menu-item index="2">品牌荣誉</el-menu-item>
                  <el-menu-item index="2">品牌实力</el-menu-item>
                  <el-menu-item index="2">合作媒体支持</el-menu-item>
                </el-submenu>
                <el-menu-item index="3">俏皮童话成长中心</el-menu-item>
                <el-menu-item index="4">1+N特色项目</el-menu-item>
                <el-submenu index="5">
                  <template slot="title">俏皮动态</template>
                  <el-menu-item index="5">最新动态</el-menu-item>
                  <el-menu-item index="5">俏皮宝宝作品</el-menu-item>
                </el-submenu>
                <el-menu-item index="6">品牌及周边</el-menu-item>
                <el-menu-item index="7">俏皮借阅</el-menu-item>
              </el-menu>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="titleMenuBox">
            <div>
              <div style="font-size: 14px">俏皮童话唯一热线</div>
              <div style="margin-top: 4px;font-size: 16px;font-weight: bold;">400-110-6121</div>
            </div>
          </div>
        </el-col>

      </el-row>
    </div>
    <div style="width: 100%">
      <img :src="require('@/assets/pcImg/banner'+activeIndex+'.png')" style="width: 100%;">
    </div>
    <div class="conterBox">
      <router-view ref="allchildHome" @handleSelect="handleSelect"/>
    </div>
    <div class="bottonMenu">
      <div style="position: relative;width: 100%;height: 100%">
        <div class="bottonMenu1">
          <div class="bottonMenu1box">
            <div class="bottonMenu1boxText">
              回到首页
            </div>
             <div class="bottonMenu1boxText" style="margin-top: 10px">
              关于俏皮童话
            </div>
             <div class="bottonMenu1boxText">
              八大智能图
            </div>
             <div class="bottonMenu1boxText">
              王牌项目赋能
            </div>
             <div class="bottonMenu1boxText">
              俏皮动态
            </div>
             <div class="bottonMenu1boxText">
              品牌荣誉
            </div>
             <div class="bottonMenu1boxText">
              合作支持
            </div>
          </div>
        <div class="bottonMenu1box">
            <div class="bottonMenu1boxText">
              关于俏皮童话
            </div>
             <div class="bottonMenu1boxText" style="margin-top: 10px">
              俏皮童话简介
            </div>
             <div class="bottonMenu1boxText">
              品牌实力
            </div>
             <div class="bottonMenu1boxText">
              品牌荣誉
            </div>
             <div class="bottonMenu1boxText">
              品牌合作
            </div>
          </div>
        <div class="bottonMenu1box">
            <div class="bottonMenu1boxText">
              绘本成长馆
            </div>
             <div class="bottonMenu1boxText" style="margin-top: 10px">
              俏皮品牌馆
            </div>
             <div class="bottonMenu1boxText">
              联系我们
            </div>
          </div>
        <div class="bottonMenu1box">
            <div class="bottonMenu1boxText">
              1+N特色项目
            </div>
             <div class="bottonMenu1boxText" style="margin-top: 10px">
              八大智能
            </div>
             <div class="bottonMenu1boxText">
              王牌项目赋能
            </div>
          </div>
        <div class="bottonMenu1box">
            <div class="bottonMenu1boxText">
              俏皮动态
            </div>
             <div class="bottonMenu1boxText" style="margin-top: 10px">
              最新动态
            </div>
             <div class="bottonMenu1boxText">
              俏皮活动
            </div>
             <div class="bottonMenu1boxText">
              俏皮宝宝
            </div>
          </div>
        <div class="bottonMenu1box">
            <div class="bottonMenu1boxText">
              品牌及周边
            </div>
             <div class="bottonMenu1boxText" style="margin-top: 10px">
              品牌展示
            </div>
             <div class="bottonMenu1boxText">
              周边展示
            </div>
          </div>
          <div class="bottonMenu1box" style="width: 200px">
            <div class="bottonMenu1boxText">
              关注我们
            </div>
            <div style="width: 200px;display: flex;flex-wrap: wrap;justify-content: space-between;align-items: center">
              <el-popover
                  placement="right"
                  width="110"
                  trigger="hover">
                <div>
                  <img src="@/assets/qrcode/gzh.png" style="width: 100%;">
                </div>
                <div slot="reference" class="bottonMenu1boxBotton">
                  <img src="@/assets/logo/wx.png" style="width: 32px">
                </div>
              </el-popover>
              <el-popover
                  placement="right"
                  width="110"
                  trigger="hover">
                <div>
                  <img src="@/assets/qrcode/sph.png" style="width: 100%;">
                </div>
                <div slot="reference" class="bottonMenu1boxBotton">
                  <img src="@/assets/logo/sph.png" style="width: 32px">
                </div>
              </el-popover>
              <el-popover
                  placement="right"
                  width="110"
                  trigger="hover">
                <div>
                  <img src="@/assets/qrcode/ks.png" style="width: 100%;">
                </div>
                <div slot="reference" class="bottonMenu1boxBotton">
                  <img src="@/assets/logo/ks.png" style="width: 32px">
                </div>
              </el-popover>
              <el-popover
                  placement="right"
                  width="110"
                  trigger="hover">
                <div>
                  <img src="@/assets/qrcode/dy.png" style="width: 100%;">
                </div>
                <div slot="reference" class="bottonMenu1boxBotton">
                  <img src="@/assets/logo/dy.png" style="width: 32px">
                </div>
              </el-popover>
              <el-popover
                  placement="right"
                  width="110"
                  trigger="hover">
                <div>
                  <img src="@/assets/qrcode/xhs.png" style="width: 100%;">
                </div>
                <div slot="reference" class="bottonMenu1boxBotton">
                  <img src="@/assets/logo/xhs.png" style="width: 32px">
                </div>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="bottonMenu2">
          <div style="text-align: center">
            <div>
              COPYRIGHT ©2024 俏皮童话 www.qiaopitonghua.com website.qipitonghua.com 俏皮童话唯一热线:400-110-6121
              <a href="https://beian.miit.gov.cn/"  style="color:black; text-decoration: none;">粤ICP备2023100241号-2</a>
            </div>
            <div style="margin-top: 10px">联系地址:广东省广州市天河区金硕一路11号 汉银广场16层</div>
          </div>
        </div>
      </div>

    </div>
<!--    <div class="allPpidwa">-->
    <div class="allPpidwa animate__animated animate__fadeOutTopLeft animate__delay-5s">
      <img src="@/assets/pcImg/home/home5_3.png" style="width: 397px;height: 78px"/>
      <img src="@/assets/pcImg/home/logo.png" style="width: 720px;" class="animate__animated animate__pulse animate__slow animate__infinite	infinite"/>
      <img src="@/assets/pcImg/home/home5_4.png" style="width: 397px;height: 78px"/>
    </div>
  </div>
</template>
<script >

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api/index'

export default {
  name: 'pcPage',
  data () {
    return {
      activeIndex: '1',
    }
  },
  // 注册组件
  components: {
  },
  mounted() {

  },
  created() {
    console.log(this.activeIndex)
    if(window.sessionStorage.getItem('key')){
      this.activeIndex = window.sessionStorage.getItem('key')
      if(this.$route.path!==["/home","/about","/storeData","/allproject","/dynamicState","/rimData","/seeApp"][parseInt(this.activeIndex)-1]){
        this.$router.push({ path: ["/home","/about","/storeData","/allproject","/dynamicState","/rimData","/seeApp"][parseInt(this.activeIndex)-1] })
      }
    }else {
      this.activeIndex ='1'
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      if(this.$route.path!==["/home","/about","/storeData","/allproject","/dynamicState","/rimData","/seeApp"][parseInt(keyPath[0])-1]){
        this.$router.push({ path: ["/home","/about","/storeData","/allproject","/dynamicState","/rimData","/seeApp"][parseInt(keyPath[0])-1] })
      }
      window.sessionStorage.setItem('key',keyPath[0]);
      this.activeIndex = keyPath[0]
      this.$forceUpdate()
    }
  },
}
</script>
<style>
.allPpidwa{
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgb(255 255 255 / 80%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 999;
  top: 0;
  left: 0;
}
.bottonMenu1boxBotton{
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}
.bottonMenu1boxBotton:hover{
  background: #BBDB7F;
}
.titleBanner{
  height: 600px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.conterBox{
  width: 100%;
  background-color: #fafafa;
  min-height: 600px;
}
.bottonMenu1{
  display: flex;
  width: 70%;
  margin: auto;
  justify-content: space-between;
}
.bottonMenu1box{
  line-height: 2.5;
  width: 130px;
  text-align: left;
}
.bottonMenu1boxText{
  cursor: pointer;
}
.bottonMenu1boxText:hover{
  color: #BBDB7F;
}
.bottonMenu{
  width: 100%;
  height:400px;
  padding-top: 30px;
  box-sizing: border-box;
}
 .bottonMenu2{
  position: absolute;
  width: 100%;color: #666666;display: flex;justify-content: center;align-items: center;height: 40px;
  bottom: 20px;
}
  .titleMenuBox{
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  .el-menu--horizontal>.el-menu-item.is-active{
    border: none!important;
    color: #BBDB7F!important;
    font-weight: bold;
  }
  .titleMenu{
    border-bottom: 2px solid #BBDB7F;
  }
  .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border: none!important;
    color: #BBDB7F!important;
    font-weight: bold;
  }
  .el-menu--horizontal>.el-submenu:focus .el-submenu__title, .el-menu--horizontal>.el-submenu:hover .el-submenu__title{
    color: #BBDB7F!important;
    font-weight: bold;
  }
  .el-menu.el-menu--horizontal{
    border: none!important;
  }
  .pcPage{
    min-width: 1500px;
  }
.el-menu--popup{
  background: linear-gradient( 180deg, #FFFFFF 0%, #F0FFC3 100%) !important;
  border-radius: 0px 0px 20px 30px !important;
  padding: 20px !important;
  box-sizing: border-box;
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title{
  background: transparent;
}
</style>
