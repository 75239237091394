import axios from 'axios'
import store from '@/store'
console.log(process.env.VUE_APP_BASE_API, '地址')
/** ************************* 全局默认配置 ************************************/
// // post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// // 请求超时时间
// axios.defaults.timeout = 5000
// // 允许携带cookie
// axios.defaults.withCredentials = true
// // 完整url = base url + request url
// axios.defaults.baseURL = process.env.VUE_APP_BASE_API

/** ************************* 实例默认配置 （多服务可创建多个实例）************************************/
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: process.env.NODE_ENV === 'production' ? './' : '/', // 如果前端和后端服务在同服务器环境下可这样配置
  timeout: 50000,
  withCredentials: true,
  headers: {
    post: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }
})

// 请求拦截
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
