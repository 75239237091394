<template>
  <div class="Pcallproject">
    <div style="width: 100%;text-align: center;margin-top: 30px;font-size: 40px;font-weight: bold">
      八大智能
    </div>
    <div class="Pchome5">
      <div class="Pchome5_1">
        <img src="@/assets/pcImg/home/home5_2.png" style="width:100%"/>
      </div>
      <div class="Pchome5_2">
        <img src="@/assets/pcImg/home/home5_5.png" style="width: 100%"/>
      </div>
      <img src="@/assets/pcImg/home/home5_3.png" style="width: 397px;height: 78px"/>
      <img src="@/assets/pcImg/home/home5_1.png" style="width: 720px;height: 720px"/>
      <img src="@/assets/pcImg/home/home5_4.png" style="width: 397px;height: 78px"/>
    </div>
    <div>
      <div style="width: 100%;margin-top: 40px">
        <img src="@/assets/pcImg/home/home4.png" style="width: 160px;height: 160px;margin: auto">
      </div>
      <div style="width: 100%;text-align: center;margin-top: 30px;font-size: 40px;font-weight: bold">
        王牌项目赋能
      </div>
      <div class="Pcallproject1">
        <div class="Pcallproject1Box" v-for="(item,index) in funengData" :key="item.newsId+index">
          <div class="Pcallproject1Box1">
            <img :src="item.newsImage.split(',')[0]" style="width: 100%;height: 180px;border-radius:30px;object-fit:cover">
          </div>
          <div class="Pcallproject1Box2">
            <div style="font-size: 20px;font-weight: bold">
              {{item.newsTitle}}
            </div>
            <div> {{item.newsSubtitle}}</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script >

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api/index'

export default {
  name: 'mobilePage',
  data () {
    return {
      funengData:[]
    }
  },
  // 注册组件
  components: {
  },
  mounted() {

  },
  methods: {
    getFuneng(){
      let parmes={
        size: 999,
        current: 1,
        newsType: 3
      }
      newsByPage(parmes).then(res => {
        console.log(res.data.data.records)
        this.funengData = res.data.data.records
      })
    },
  },
  created () {
    this.getFuneng()
  }
}
</script>
<style>
.Pcallproject1Box2{
  width: 894px;
  height: 182px;
  background: #E8F5FF;
  border-radius: 50px 20px 20px 20px;
  box-sizing: border-box;
  padding: 40px 30px 30px;
  text-align: left;
  line-height: 2;
  overflow: auto;

}
.Pcallproject1Box1 {
  width: 282px;
  height: 182px;
}
.Pcallproject1Box{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}
.Pcallproject1{
  width: 1200px;
  margin: auto;
}
.Pcallproject{
  padding-top: 40px;
  padding-bottom: 40px;
}
.Pchome5 {
  width: 1300px;
  display: flex;
  height: 900px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  margin-top: 80px;
}

.Pchome5_1 {
  width: 167px;
  position: absolute;
  top: 20px;
  left: 140px;
}

.Pchome5_2 {
  width: 124px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

</style>
