<template>
  <div class="PhonerimPage">
    <div style="width: 229px;margin-top: 24px;margin-left: 40px">
      <img src="@/assets/phoneImg/home/home2.png" style="width: 100%">
    </div>
    <div style="width: 100%;margin-top: 20px;text-align: center">
      <img src="@/assets/pcImg/rim2.png" style="width: 90vw;margin: auto">
    </div>
    <div style="width: 100%;text-align: center;font-size: 24px;font-weight: bold;margin-top: 20px">
      俏皮周边
    </div>
    <div class="PhonerimPageConter">
      <div class="PhonerimPageConter1"  v-for="(item,index) in rimData" :key="item.newsId+index">
        <el-image
            style="width: 100%;height: 100%;border-radius: 10px"
            :src="item.newsImage"
            :preview-src-list="[item.newsImage]">
        </el-image>
      </div>
    </div>
  </div>
</template>
<script >

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api/index'

export default {
  name: 'mobilePage',
  data() {
    return {
      rimData: []
    }
  },
  // 注册组件
  components: {},
  mounted() {

  },
  methods: {
    getrimData() {
      let parmes = {
        size: 999,
        current: 1,
        newsType: 5
      }
      newsByPage(parmes).then(res => {
        this.rimData = res.data.data.records
      })
    },
  },
  created() {
    this.getrimData()
  }
}
</script>
<style>
.PhonerimPage {
  padding-top: 20px;
  padding-bottom: 20px;
}

.PhonerimPageConter {
  width: 90vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
}

.PhonerimPageConter1 {
  width: 48%;
  border-radius: 10px;
  height: 120px;
  margin-top: 12px;
}
</style>
