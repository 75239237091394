<template>
  <div class="mobilePage">


<!--    <div class="conterBox">-->
<!--      <router-view ref="allchildHome"/>-->
<!--    </div>-->

  </div>
</template>
<script >

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api/index'

export default {
  name: 'mobilePage',
  data () {
    return {

    }
  },
  // 注册组件
  components: {
  },
  mounted() {

  },
  methods: {

  },
  created () {
  }
}
</script>
<style>
  .mobilePage{

  }
</style>
