<template>
  <div class="mobileHome">
    <div class="mobileHome1">
      <div style="width: 24px;height: 24px">
        <img src="@/assets/phoneImg/home/home1.png" style="width: 100%">
      </div>
      <a style="margin-left: 20px;color: #000000;text-decoration: none;" href="tel:4001106121">联系我们：400-110-6121</a>
    </div>
    <div style="width: 229px;margin-top: 24px;margin-left: 40px">
      <img src="@/assets/phoneImg/home/home2.png" style="width: 100%">
    </div>
    <div style="width:85vw;margin-top: 24px;margin-left: 40px;text-align: left">
      俏皮童话绘本成长中心，依托 10年来对儿童绘本阅读行业的研究沉淀，2023年率先启用“1+N 元宇宙"新概念，乘势而行，布局全国。专业塑造 0~14 岁孩童绘本阅读寓教于乐服务链，一体化打造家长与孩子一起学习共同成长的平台。注重培养孩子的记忆力、观察力、想象力、创造力、表达力等各项能力，激发孩童的潜能和情感，以满足孩童全面健康成长的教育需求。同步助力教育改革，让孩子成为未来社会的英人才，持续提升中国孩子的核心素养与国际竞争力。真正意义上做到了打造专属宝贝的成长计划。
    </div>
    <div class="mobileHome2">
      <div style="width: 90px">
        <img src="@/assets/phoneImg/home/home3_1.png" style="width: 100%">
      </div>
      <div style="width: 90px">
        <img src="@/assets/phoneImg/home/home3_2.png" style="width: 100%">
      </div>
      <div style="width: 90px">
        <img src="@/assets/phoneImg/home/home3_3.png" style="width: 100%">
      </div>
      <div style="width: 90px">
        <img src="@/assets/phoneImg/home/home3_4.png" style="width: 100%">
      </div>
    </div>
    <div class="mobileHome3">
      <div class="mobileHome3_1" @click="goRouter(2)">
        查看更多
        <i class="el-icon-right"></i>
      </div>
    </div>
    <div style="width: 100%;margin-top: 24px;">
      <img src="@/assets/phoneImg/home/home4.png" style="width: 100%">
    </div>
    <div style="width: 100%;margin-top: 24px;">
      <img src="@/assets/phoneImg/home/home5.png" style="width: 80px;margin: auto">
    </div>
    <div style="width: 100%;margin-top: 10px;font-size: 20px;font-weight: bold">
      王牌项目赋能
    </div>
    <div class="mobileHome4">
      <div v-for="(image, index) in funengData" :key="image.primaryImage+index" class="mobileHome41">
        <img :src="currentSrc(image)" style="width: 100%;cursor: pointer;height: 108px;object-fit:cover;border-radius: 10px 22px 10px 10px;" :style="imageStyle(image)"
             @click="toggleImage(index)"/>
      </div>
    </div>
    <div class="mobileHome3">
      <div class="mobileHome3_1" @click="goRouter(2)">
        查看更多
        <i class="el-icon-right"></i>
      </div>
    </div>
    <div style="width: 100%;margin-top: 24px;">
      <img src="@/assets/phoneImg/badalun.png" style="width: 100%">
    </div>
    <div style="width: 100%;margin-top: 10px;font-size: 20px;font-weight: bold">
      俏皮动态
    </div>
    <div class="mobileHome5">
      <div>
        <div style="font-size: 12px;font-weight: bold">
          {{dongtaiData.newsTitle}}
        </div>
        <div style="color: #BBDB7F;font-size: 12px">
          {{dongtaiData.newsYears}}
        </div>
        <div style="font-size: 10px">
          {{dongtaiData.newsSubtitle}}
        </div>
      </div>
      <div style="width: 100px;">
        <img :src="dongtaiData.newsImage" style="width: 100%;">
      </div>
    </div>
    <div style="width: 90vw;margin: 24px auto; padding-bottom: 24px;">
      <img src="@/assets/phoneImg/hezuo.png" style="width: 100%">
    </div>


  </div>
</template>
<script>

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api/index'

export default {
  name: 'mobileHome',
  data() {
    return {
      funengData:[],
      dongtaiData:{}
    }
  },
  // 注册组件
  components: {},
  mounted() {

  },
  methods: {
    toggleImage(index) {
      this.funengData[index].isFlipped = !this.funengData[index].isFlipped;
    },
    imageStyle(image) {
      return {
        transform: image.isFlipped ? 'rotateY(360deg)' : 'rotateY(0deg)',
        transition: 'transform 2s',
      };
    },
    currentSrc(image) {
      let imgUrl = image.isFlipped ? image.secondaryImage : image.primaryImage;
      if (imgUrl.includes('//')) {
        return imgUrl
      } else {
        return require("../../../assets/phoneImg/shuxing/" + imgUrl)
      }
    },
    goRouter(index) {
      this.$parent.changeMenus(index)
    },
    getFuneng(){
      let parmes2={
        size: 999,
        current: 1,
        newsType: 2
      }
      newsByPage(parmes2).then(res => {
        this.dongtaiData = res.data.data.records[0]
      })
      let parmes={
        size: 999,
        current: 1,
        newsType: 3
      }
      newsByPage(parmes).then(res => {
        console.log(res.data.data.records)
        this.funengData = res.data.data.records.map((item,index)=>{
          var ImgData = ["homeFu1.png", "homeFu2.png", "homeFu3.png", "homeFu4.png", "homeFu5.png", "homeFu6.png", "homeFu7.png", "homeFu8.png",]
          var imageIndex = index % ImgData.length;
          var primaryImage = ImgData[imageIndex];
          console.log(item.newsImage.split(','))
          return {
            ...item,
            primaryImage: item.newsImage.split(',').length>1?item.newsImage.split(',')[1]:primaryImage,
            secondaryImage: item.newsImage.split(',').length>0?item.newsImage.split(',')[0]:primaryImage,
            isFlipped: false,
          }
        })
      })
    },
  },
  created() {
    this.getFuneng()
  }
}
</script>
<style>
.mobileHome5{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  margin: auto;
  padding: 10px;
  box-sizing: border-box;
  background: #E8F5FF;
  border-radius: 10px;
  line-height: 1.5;
  text-align: left;
  margin-top: 10px;
}
.mobileHome41{
  width: 48%;
  margin-top: 20px;
}
.mobileHome4{
  width: 90vw;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mobileHome3 {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.mobileHome3_1 {
  background-repeat: no-repeat;
  width: 180px;
  height: 41px;
  background-size: cover;
  background-image: url("@/assets/phoneImg/home/lujing.png");
  display: flex;
  justify-content: center;
  align-items: center;

}

.mobileHome2 {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.mobileHome {

}

.mobileHome1 {
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-image: url("@/assets/phoneImg/home/phone1.png");
  height: 50px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0px 10px 0px 40px;
  box-sizing: border-box;
}
</style>
