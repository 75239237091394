<template>
  <div class="PhonedynamicState">
    <div class="PhonedynamicStateBox">
      <div class="PhonedynamicState1">
        <div :class="menusIndex ==2 ?'PhonedynamicState1_1':'PhonedynamicState1_2'" @click="changeImdex(2)">俏皮动态
        </div>
        <div :class="menusIndex ==1?'PhonedynamicState1_1':'PhonedynamicState1_2'" @click="changeImdex(1)"
             style="margin-left: 20px">俏皮宝贝作品
        </div>
      </div>
      <div style="width: 100%" v-if="menusIndex ==2">
        <div class="PhonedynamicState2" v-for="(item,index) in formList" :key="item.newsId+index"
             @click="changeItme(item)">
          <div>
            <div style="    font-size: 10px;
    font-weight: bold;
    width: 97%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;">
              {{ item.newsTitle }}
            </div>
            <div style="color: #BBDB7F;font-size: 12px">
              {{ item.newsYears }}
            </div>
            <div style="font-size: 10px">
              {{ item.newsSubtitle }}
            </div>
          </div>
          <div style="width: 162px;height: 100px">
            <img :src="item.newsImage" style="width: 162px;height: 100px;border-radius: 10px;object-fit: cover">
          </div>
        </div>
      </div>
      <div style="width: 100%" v-if="menusIndex ==1">
        <div class="PhonedynamicState4">
          <div v-for="(item,index) in formList" :key="item.newsId" style="margin-top: 20px;width: 48%">
            <div style="width: 100%;height: 142px">
              <el-image
                  style="width: 100%;height: 100%;border-radius: 10px"
                  :src="item.newsImage"
                  :preview-src-list="[item.newsImage]">
              </el-image>
            </div>
            <div>
              {{ item.newsTitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="PhonedynamicState3" style="margin-top: 20px">
        <el-pagination
            background
            layout="prev, pager, next"
            :current-page="tablePage.current"
            :page-size="tablePage.size"
            @size-change="handleSizeChange"
            @current-change="handlePageChange"
            :total="tablePage.total">
        </el-pagination>
      </div>

    </div>
    <el-dialog
        :title="itemData.newsTitle"
        :visible.sync="dialogVisible"
        width="90%">
      <div v-html="itemData.newsRich" v-if="itemData.newsRich&&itemData.newsRich!==''" class="HtmlData"></div>
      <div v-else style="width: 100%;">
        <img :src="itemData.newsImage" style="width: 100%;">
      </div>
    </el-dialog>
  </div>
</template>
<script>

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api/index'

export default {
  name: 'mobilePage',
  data() {
    return {
      dialogVisible: false,
      itemData: {},
      tablePage: {
        current: 1, // 第几页
        size: 10, // 每页多少条
        total: 0 // 总记录数
      },
      menusIndex: 2,
      formList: [],
    }
  },
  // 注册组件
  components: {},
  mounted() {

  },
  methods: {
    changeItme(item) {
      this.dialogVisible = true
      this.itemData = item
    },
    getPageData(parmes) {
      parmes.newsType = this.menusIndex
      newsByPage(parmes).then(res => {
        this.formList = res.data.data.records
        this.tablePage.total = res.data.data.total
      })
    },
    ract() {
      this.formList = []
      this.tablePage = {
        current: 1, // 第几页
        size: 10, // 每页多少条
        total: 0 // 总记录数
      }
      this.getPageData(this.tablePage)
    },
    handlePageChange(currentPage) {
      this.tablePage.current = currentPage
      this.getPageData(this.tablePage)
    },
    handleSizeChange(pageSize) {
      this.tablePage.size = pageSize
      this.getPageData(this.tablePage)
    },
    changeImdex(index) {
      this.menusIndex = index
      this.ract()
    }
  },
  created() {
    this.ract()
  }
}
</script>
<style>
.PhonedynamicState4 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.PhonedynamicState1 {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: start;
  height: 45px;
  align-items: center;
}

.PhonedynamicState2 {
  width: 100%;
  background: #E8F5FF;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 15px 10px 15px;
  text-align: left;
  line-height: 2;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  margin-top: 10px;
}

.PhonedynamicState1_1 {
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.PhonedynamicState1_2 {
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.PhonedynamicStateBox {
  width: 90vw;
  margin: auto;
}

.PhonedynamicState {
  padding-bottom: 20px;
  padding-top: 20px;
}

.HtmlData img {
  width: 100%;
}
</style>
