<template>
  <div class="mobileStoreData">
    <div style="width: 100%">
      <img src="@/assets/pcImg/storeData/storeData1.png" style="width: 50px;">
    </div>
    <div style="width: 100%;text-align: center;margin-top: 10px;font-size: 20px;font-weight: bold">
      俏皮品牌馆
    </div>
    <div class="mobileStoreData1">
      <div style="width: 40%;">
        <img src="@/assets/pcImg/storeData/storeDataBox1.png" style="width: 95%;height:72px;object-fit:cover;border-radius: 10px">
        <img src="@/assets/pcImg/storeData/storeDataBox2.png" style="width: 95%;height:72px;margin-top: 10px;object-fit:cover;border-radius: 10px">
      </div>
      <div style="width: 59%;">
        <img src="@/assets/pcImg/storeData/storeDataBox5.png" style="width: 95%;height: 158px;object-fit:cover;border-radius: 10px">
      </div>
    </div>
    <div class="mobileHome1" style="margin-top: 20px">
      <div style="width: 24px;height: 24px">
        <img src="@/assets/phoneImg/home/home1.png" style="width: 100%">
      </div>
      <div style="margin-left: 20px">
        联系我们：400-110-6121
      </div>
    </div>
    <div class="mobileStoreData1">
      <div style="width: 48%;margin-top: 20px;" v-for="(item,index) in storesByListAllData" :key="item.storesName+index">
        <el-image
            style="width: 100%;border-radius: 10px;height: 120px"
            :src="item.storesImage"
            :preview-src-list="[item.storesImage]">
        </el-image>
        <div class="mobileStoreData1Text">{{item.storesName}}</div>
      </div>
    </div>
  </div>
</template>
<script >

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api/index'

export default {
  name: 'mobileStoreData',
  data () {
    return {
      storesByListAllData:[]
    }
  },
  // 注册组件
  components: {
  },
  mounted() {

  },
  methods: {
    getstoresByList(){
      storesByList().then(res => {
        this.storesByListAllData = res.data.data
      })
    },
  },
  created () {
    this.getstoresByList()
  }
}
</script>
<style>
.mobileStoreData1{
  width: 90vw;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.mobileStoreData1Text{
  width: 100%;
  height: 30px;
  font-size: 12px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient: vertical;
}
  .mobileStoreData{
    padding-bottom: 24px;
    padding-top: 24px;
  }
</style>
