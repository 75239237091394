<template>
  <div class="Pchome">
    <div class="Pchome1">
      <img src="@/assets/pcImg/home/home1.png" style="width: 100%">
    </div>
    <div class="Pchome2">
      <img src="@/assets/pcImg/home/home2_1.png"
           style=" width: 153px;height: 102px;position: absolute;bottom: 0;left: 0">
      <img src="@/assets/pcImg/home/home2_2.png" style=" width: 143px;height: 254px;position: absolute;top: 0;right: 0">
      <div class="Pchome2_1">
        <div style="font-size: 40px;font-weight: bold;">俏皮童话绘本成长中心</div>
        <div class="Pchome2_11">
          <div style="width: 256px;height: 168px; margin-top: 30px;" v-for="(item,index) in chengjiuData"
               :key="item.newsId+index" class="Pchome2_12H">
            <div class="Pchome2_12">
              <div>
                {{ item.newsTitle }}
              </div>
              <div>
                {{ item.newsSubtitle }}
              </div>
            </div>
            <img :src="item.newsImage" style="width: 100%;height: 100%">
          </div>
        </div>
      </div>
    </div>
    <div class="Pchome3">
      <img src="@/assets/pcImg/home/home3.png" style="width: 100%">
    </div>
    <div>
      <div style="width: 100%;margin-top: 120px">
        <img src="@/assets/pcImg/home/home4.png" style="width: 160px;height: 160px;margin: auto">
      </div>
      <div style="width: 100%;text-align: center;margin-top: 30px;font-size: 40px;font-weight: bold">
        王牌项目赋能
      </div>
      <div class="Pchome4">
        <div v-for="(image, index) in home4Data" :key="image.primaryImage+index" class="Pchome4_1">
          <!--          <img src="homeFu2.png" style="width: 100%"/>-->
          <img :src="currentSrc(image)" style="width: 100%;cursor: pointer;height: 182px;object-fit:cover;border-radius: 20px 45px 20px 20px;" :style="imageStyle(image)"
               @click="toggleImage(index)"/>
        </div>
      </div>
    </div>
    <div class="Pchome5">
      <div class="Pchome5_1">
        <img src="@/assets/pcImg/home/home5_2.png" style="width:100%"/>
      </div>
      <div class="Pchome5_2">
        <img src="@/assets/pcImg/home/home5_5.png" style="width: 100%"/>
      </div>
      <img src="@/assets/pcImg/home/home5_3.png" style="width: 397px;height: 78px"/>
      <img src="@/assets/pcImg/home/home5_1.png" style="width: 720px;height: 720px"/>
      <img src="@/assets/pcImg/home/home5_4.png" style="width: 397px;height: 78px"/>
    </div>
    <div class="Pchome6">
      <div style="width: 100%;font-size: 40px;font-weight: bold;text-align: left">俏皮动态</div>
      <div style="width: 100%;margin-top: 40px">
        <el-row :gutter="20">
          <el-col :span="11">
            <div>
              <div style="width: 510px;height: 280px;">
                <img src="@/assets/pcImg/home/home6_1.png" style="width: 100%">
              </div>
              <div style="font-size: 16px;margin-top: 12px">
                <span style="font-weight: bold">俏皮宝贝</span>
                <span class="Pchome6_1">「宝贝作品」俏皮宝贝风采展示</span>
              </div>
            </div>
          </el-col>
          <el-col :span="13">
            <div>
              <div style="width: 100%">
                <img src="@/assets/pcImg/home/home6_2.png" style="width: 100%">
              </div>
              <div v-for="(item,index) in dongtaiData" :key="item.newsId+index" @click="changeGo(5)"
                   style="font-size: 16px;display: flex;justify-content: start;width: 90%;margin: auto;margin-top: 12px;">
                <div style="font-weight: bold">活动</div>
                <div class="Pchome6_1">
                  <span>【俏皮童话|喜讯】 {{ item.newsTitle }}</span>
                  <span> {{ item.newsYears }}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="Pchome7">
      <div class="Pchome7scroll-content" :style="contentStyle()">
        <div class="Pchome7content1 Pchome7content">
          <div style="width: 168px;height: 64px;margin-left: 40px;margin-top: 20px" v-for="item in 7">
            <img :src="require('../../../assets/pcImg/home/ppf/ppf'+item+'.png')" style="width: 100%">
          </div>
        </div>
        <div class="Pchome7content2 Pchome7content">
          <div style="width: 168px;height: 64px;margin-left: 40px;margin-top: 20px" v-for="item in 8">
            <img :src="require('../../../assets/pcImg/home/ppf/ppfa'+item+'.png')" style="width: 100%">
          </div>
        </div>
        <div class="Pchome7content3 Pchome7content">
          <div style="width: 168px;height: 64px;margin-left: 40px;margin-top: 20px" v-for="item in 9">
            <img :src="require('../../../assets/pcImg/home/ppf/ppfb'+item+'.png')" style="width: 100%">
          </div>
        </div>
      </div>
      <div class="Pchome7Back">
        <div style="font-size: 48px;font-weight: bold">合作媒体支持</div>
        <div style="font-size: 32px;font-weight: bold">MEDIA SUPPORT</div>
      </div>
    </div>

  </div>
</template>
<script>

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api/index'

export default {
  name: 'mobilePage',
  data() {
    return {
      chengjiuData: [],
      BaobeiData: [],
      dongtaiData: [],
      home4Data: [],
      position: 0,
    }
  },
  // 注册组件
  components: {},
  computed: {},
  mounted() {
    this.startScroll();
  },
  methods: {
    changeGo(index) {
      this.$parent.handleSelect(index,[index])
    },
    getChengjiu() {
      let parmes = {
        size: 999,
        current: 1,
        newsType: 4
      }
      newsByPage(parmes).then(res => {
        console.log(res.data.data.records)
        this.chengjiuData = res.data.data.records
      })
      let parmes2 = {
        size: 999,
        current: 1,
        newsType: 3
      }
      newsByPage(parmes2).then(res => {
        this.home4Data = res.data.data.records.map((item, index) => {
          var ImgData = ["homeFu1.png", "homeFu2.png", "homeFu3.png", "homeFu4.png", "homeFu5.png", "homeFu6.png", "homeFu7.png", "homeFu8.png",]
          var imageIndex = index % ImgData.length;
          var primaryImage = ImgData[imageIndex];
          return {
            ...item,
            primaryImage: item.newsImage.split(',').length>1?item.newsImage.split(',')[1]:primaryImage,
            secondaryImage: item.newsImage.split(',').length>0?item.newsImage.split(',')[0]:primaryImage,
            isFlipped: false,
          }
        })
      })
      let parmes3 = {
        size: 3,
        current: 1,
        newsType: 1
      }
      newsByPage(parmes3).then(res => {
        this.dongtaiData = res.data.data.records
      })
      let parmes4 = {
        size: 999,
        current: 1,
        newsType: 2
      }
      newsByPage(parmes4).then(res => {
        this.BaobeiData = res.data.data.records
      })


    },
    contentStyle() {
      return {
        transform: `translateX(${this.position}px)`,
        transition: 'transform 0.5s ease-in-out',
      };
    },
    startScroll() {
      const contentWidth = 1400; // 单个内容的宽度
      const containerWidth = 1200; // 容器宽度
      const resetPosition = containerWidth;

      setInterval(() => {
        this.position -= 1;

        // 当滚动内容即将离开视图时，重置位置
        if (-this.position >= contentWidth) {
          setTimeout(() => {
            this.position = resetPosition;
          }, 500); // 这个延迟应该与CSS过渡时间相匹配
        }
      }, 16); // 16毫秒大约是60FPS的帧率
    },
    imageStyle(image) {
      return {
        transform: image.isFlipped ? 'rotateY(360deg)' : 'rotateY(0deg)',
        transition: 'transform 2s',
      };
    },
    currentSrc(image) {
      console.log(image)
      let imgUrl = image.isFlipped ? image.secondaryImage : image.primaryImage;
      if (imgUrl.includes('//')) {
        return imgUrl
      } else {
        return require("../../../assets/phoneImg/shuxing/" + imgUrl)
      }
    },
    toggleImage(index) {
      this.home4Data[index].isFlipped = !this.home4Data[index].isFlipped;
    },

  },
  created() {
    this.getChengjiu()
  }
}
</script>
<style>

.Pchome7content1 {
  display: flex;
  justify-content: end;
}

.Pchome7content2 {
  display: flex;
  justify-content: center;
}

.Pchome7content3 {
  display: flex;
  justify-content: start;
}

.Pchome7content {
  width: 1900px;
  line-height: 2;
}

.Pchome7Back {
  text-align: left;
  padding: 50px 50px 10px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(147deg, #EBFFAA 0%, rgba(226, 254, 143, 0.8) 44%, rgba(209, 250, 90, 0) 100%);
  border-radius: 30px;
}

.Pchome7 {
  width: 1200px;
  height: 324px;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 100px;
  position: relative;
  overflow: hidden;
  padding-top: 40px;
  box-sizing: border-box;
}

.Pchome6 {
  width: 1200px;
  margin: auto;
}

.Pchome6_1 {
  cursor: pointer;
  margin-left: 10px;
}

.Pchome6_1:hover {
  color: #BBDB7F;
  margin-left: 20px;
}

.Pchome5 {
  width: 1300px;
  display: flex;
  height: 900px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  margin-top: 80px;
}

.Pchome5_1 {
  width: 167px;
  position: absolute;
  top: 20px;
  left: 140px;
}

.Pchome5_2 {
  width: 124px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.Pchome4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 1200px;
  margin: auto;
}

.Pchome4_1 {
  width: 282px;
  height: 182px;
  margin-top: 20px;
}

.Pchome3 {
  width: 100%;
  margin-top: 80px;
}

.Pchome2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1325px;
  min-height: 800px;
  margin: auto;
  margin-top: 80px;
}

.Pchome2_12H {
  cursor: pointer;
}

.Pchome2_12H:hover .Pchome2_12 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.Pchome2_12 {
  display: none;
  overflow: auto;
  padding: 20px;
  //display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffc7;
  position: absolute;
  width: 256px;
  height: 168px;
  border-radius: 20px;
  font-weight: bold;
  box-sizing: border-box;
}

.Pchome2_11 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.Pchome2_1 {
  width: 1200px;
  min-height: 720px;
  background: #E8F5FF;
  border-radius: 20px;
  padding: 50px 30px 75px;
  box-sizing: border-box;
}

.Pchome {
  padding-top: 40px;
  padding-bottom: 40px;
}

.Pchome1 {
  width: 1200px;
  margin: auto;
}
</style>
