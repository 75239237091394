<template>
  <div class="mobilePage">
    <div style="width: 100%;margin-top: 24px;">
      <img src="@/assets/phoneImg/badalun.png" style="width: 100%">
    </div>
    <div style="width: 100%;margin-top: 24px;">
      <img src="@/assets/phoneImg/home/home5.png" style="width: 80px;margin: auto">
    </div>
    <div style="width: 100%;margin-top: 10px;font-size: 20px;font-weight: bold">
      王牌项目赋能
    </div>
    <div class="mobileHome4">
      <div v-for="(image, index) in funengData" :key="image.primaryImage+index" class="mobileHome41">
        <img :src="currentSrc(image)" style="width: 100%;cursor: pointer;height: 108px;object-fit:cover;border-radius: 10px 22px 10px 10px;" :style="imageStyle(image)"
             @click="toggleImage(index)"/>
      </div>
    </div>
  </div>
</template>
<script >

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api/index'

export default {
  name: 'mobilePage',
  data () {
    return {
      funengData:[]
    }
  },
  // 注册组件
  components: {
  },
  mounted() {

  },
  methods: {
    toggleImage(index) {
      this.funengData[index].isFlipped = !this.funengData[index].isFlipped;
    },
    imageStyle(image) {
      return {
        transform: image.isFlipped ? 'rotateY(360deg)' : 'rotateY(0deg)',
        transition: 'transform 2s',
      };
    },
    currentSrc(image) {
      let imgUrl = image.isFlipped ? image.secondaryImage : image.primaryImage;
      if (imgUrl.includes('//')) {
        return imgUrl
      } else {
        return require("../../../assets/phoneImg/shuxing/" + imgUrl)
      }
    },
    getFuneng(){
      let parmes={
        size: 999,
        current: 1,
        newsType: 3
      }
      newsByPage(parmes).then(res => {
        console.log(res.data.data.records)
        this.funengData = res.data.data.records.map((item,index)=>{
          var ImgData = ["homeFu1.png", "homeFu2.png", "homeFu3.png", "homeFu4.png", "homeFu5.png", "homeFu6.png", "homeFu7.png", "homeFu8.png",]
          var imageIndex = index % ImgData.length;
          var primaryImage = ImgData[imageIndex];
          console.log(item.newsImage.split(','))
          return {
            ...item,
            primaryImage: item.newsImage.split(',').length>1?item.newsImage.split(',')[1]:primaryImage,
            secondaryImage: item.newsImage.split(',').length>0?item.newsImage.split(',')[0]:primaryImage,
            isFlipped: false,
          }
        })
      })
    },
  },
  created () {
    this.getFuneng()
  }
}
</script>
<style>
.mobilePage{

}
</style>
