<template>
  <div class="rimPage">
    <div style="width: 100%;text-align: center">
      <img src="@/assets/pcImg/rim1.png" style="width: 400px">
    </div>
    <div style="width: 100%;margin-top: 20px;text-align: center">
      <img src="@/assets/pcImg/rim2.png" style="width: 1200px">
    </div>
    <div style="width: 100%;text-align: center;font-size: 48px;font-weight: bold;margin-top: 40px">
      俏皮周边
    </div>
    <div class="rimPageConter">
      <div class="rimPageConter1"  v-for="(item,index) in rimData" :key="item.newsId+index">
        <el-image
            style="width: 100%;height: 100%;border-radius: 10px"
            :src="item.newsImage"
            :preview-src-list="[item.newsImage]">
        </el-image>
      </div>
    </div>
  </div>
</template>
<script >

import {newsByPage, rotateImagesByPages, selectByCategorySpu, selectGoodCateGory, storesByList} from '@/api/index'

export default {
  name: 'mobilePage',
  data () {
    return {
      rimData:[]
    }
  },
  // 注册组件
  components: {
  },
  mounted() {

  },
  methods: {
    getrimData(){
      let parmes={
        size: 999,
        current: 1,
        newsType: 5
      }
      newsByPage(parmes).then(res => {
        this.rimData = res.data.data.records
      })
    },
  },
  created () {
    this.getrimData()
  }
}
</script>
<style>
.rimPage{
  padding-top: 40px;
  padding-bottom: 80px;
}
.rimPageConter{
  width: 1200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
}
.rimPageConter1{
  width: 384px;
  height: 284px;
  margin-top: 24px;
}
</style>
